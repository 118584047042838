import React, { Component } from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import styled from 'styled-components';

import '../globalStyles.css';
import Layout from '../components/Layout';

const TermsContainer = styled.div`
  margin-bottom: 120px;
  color: var(--color-dark-grey);
  font-size: 17px;

  @media (max-width: 1350px) {
    margin-bottom: 10%;
    font-size: 16px;
  }

  @media (max-width: 550px) {
    font-size: 14px;
  }
`;

const TermsText = styled.div`
  margin: auto;
  width: 55%;

  @media (max-width: 1620px) {
    width: 65%;
  }

  @media (max-width: 1350px) {
    width: 70%;
  }

  @media (max-width: 1023px) {
    width: 85%;
  }

  & h1 {
    font-size: 60px;
    text-align: center;
    margin-top: 120px;
    margin-bottom: 0;

    @media (max-width: 1350px) {
      font-size: 50px;
      margin-top: 10%;
    }

    @media (max-width: 1023px) {
      font-size: 45px;
    }

    @media (max-width: 550px) {
      font-size: 35px;
    }

    @media (max-width: 375px) {
      font-size: 34px;
    }
  }

  & h4 {
    font-size: 40px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 80px;

    @media (max-width: 1350px) {
      font-size: 30px;
      margin-bottom: 10%;
    }

    @media (max-width: 550px) {
      font-size: 22px;
    }
  }

  & h2 {
    font-size: 25px;
    margin-top: 50px;

    @media (max-width: 1350px) {
      font-size: 20px;
    }

    @media (max-width: 550px) {
      font-size: 16px;
    }
  }

  & a {
    color: var(--color-dark-grey);
    text-decoration: none;
    transition: all ease 0.3s;
    background-size: auto 175%;
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.6),
    0 4px 0 -3px rgba(52, 191, 201, 0.6),
    inset 0 0 0 0 rgba(52, 191, 201, 0.6);
  }
  
  & a:hover {
    background-position-y: 105%;
    color: var(--color-white);
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.8),
    0 0px 0 2px rgba(52, 191, 201, 0.8),
    inset 0 -1.5em 0 0 rgba(52, 191, 201, 0.8);
  }

  & p {
    font-size: 17px;

    @media (max-width: 1350px) {
      font-size: 16px;
    }
      @media (max-width: 550px) {
      font-size: 14px;
    }
  }
`;

const SectionNaviagtion = styled.div`
  font-size: 17px;  
  display: flex;
  gap: 20px;
  justify-content: right;
  padding-top: 20px;
  margin: auto;
  width: 55%;

  @media (max-width: 1620px) {
    width: 65%
  }

  @media (max-width: 1350px) {
    width: 70%;
  }

  @media (max-width: 1023px) {
    width: 85%;
  }
`;

const SectionLinks = styled.a`
  text-decoration: none;
  color: var(--color-dark-grey);
  transition: all ease 0.3s;
  background-size: auto 175%;
  box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.6),
  0 4px 0 -3px rgba(52, 191, 201, 0.6),
  inset 0 0 0 0 rgba(52, 191, 201, 0.6);


  &:hover {
    background-position-y: 105%;
    color: var(--color-white);
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.8),
    0 0px 0 2px rgba(52, 191, 201, 0.8),
    inset 0 -1.5em 0 0 rgba(52, 191, 201, 0.8);
  }

`;

const ButtonArea = styled.div`
  width: 55%;
  margin: auto;
  margin-top: 60px;

  @media (max-width: 1620px) {
    width: 65%;
  }

  @media (max-width: 1350px) {
    width: 70%;
  }

  @media (max-width: 1023px) {
    width: 85%;
    padding-bottom: 5%;
  }
`;

const DownloadButton = styled.div`
  color: var(--color-teal);

  & a, .button {
    text-decoration: none;
    color: var(--color-teal);
    font-weight: 700;

    @media (max-width: 1350px) {
      font-size: 18px;
    }

    @media (max-width: 550px) {
      font-size: 15px;
    }
  }
`;
export default class Terms extends Component {
  static propTypes = {
    data: PropTypes.object,
    pageContext: PropTypes.object,
  };

  render() {
    const { data, pageContext } = this.props;
    const termsVersions = data?.allMdx?.edges || [];

    let canonicalLinkPrefix;

    if (pageContext.locale === 'en') {
      canonicalLinkPrefix = 'https://presono.com/en';
    } else {
      canonicalLinkPrefix = 'https://presono.com';
    }

    return (
      <Layout currentPath={pageContext.originalPath}>
        <Helmet>
          <title>{`${data?.mdx?.frontmatter?.title} | presono`}</title>
          <meta
            name="description"
            content={data?.mdx?.frontmatter?.snippetTextTerms}
          />

          <link rel="canonical" href={canonicalLinkPrefix + pageContext.originalPath} />

          <meta property="og:title" content={data?.mdx?.frontmatter?.title} />
          <meta property="og:description" content={data?.mdx?.frontmatter?.snippetTextTerms} />
          <meta name="twitter:title" content={data?.mdx?.frontmatter?.title} />
          <meta name="twitter:description" content={data?.mdx?.frontmatter?.snippetTextTerms} />
        </Helmet>
        <TermsContainer tabIndex="-1">
          <SectionNaviagtion>
            { termsVersions.map((terms, index) => (
              <SectionLinks
                href={`#${terms?.node?.frontmatter?.linkTitle}`}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                onClick={() => {
                  if (typeof window !== 'undefined') {
                    window.setTimeout(() => {
                      document.body.scrollIntoView();
                    }, 100);
                  }
                }}
              >
                {terms?.node?.frontmatter?.linkTitle}
              </SectionLinks>
            ))}
          </SectionNaviagtion>
          { termsVersions.map((terms, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div id={terms?.node?.frontmatter?.linkTitle} key={index}>
              <TermsText>
                <MDXRenderer>{terms?.node?.body || 'TEXT NOT FOUND'}</MDXRenderer>
              </TermsText>
              <ButtonArea>
                <DownloadButton>
                  <a
                    href={terms?.node?.frontmatter?.termsPDF}
                    download={terms?.node?.frontmatter?.downloadNameTerms}
                    className="button"
                  >
                    <span>{data?.mdx?.frontmatter?.downloadButtonTerms}</span>
                  </a>
                </DownloadButton>
              </ButtonArea>
            </div>
          ))}
        </TermsContainer>
      </Layout>
    );
  }
}

export const query = graphql`
query TermsQuery($locale: String!) {
  mdx(
    fields: { locale: { eq: $locale } }
    frontmatter: { layout: { eq: "terms" } }
  ) {
    frontmatter {
      title
      snippetTextTerms
      downloadButtonTerms
    }
  }
  allMdx(
    filter: {frontmatter: {layout: {eq: "term-versions"}}, fields: {locale: {eq: $locale}}}
  ) {
    edges {
      node {
        frontmatter {
          title
          linkTitle
          termsPDF
          downloadNameTerms
        }
        body
      }
    }
  }
}
`;
